import React, {useEffect, useState} from 'react';
import ArticleEditor from 'rev.sdk.js/Components/ArticleEditor';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../errors';
import BackButton from '../Components/BackButton';
import styled from 'styled-components';
import Block from '../Widgets/Block';
import Row from '../Widgets/Row';
const qs = require('query-string');
import {navigate} from 'gatsby';

const UISTATE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export default function BlogDetailPage(props) {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const [uiState] = useState(!!id ? UISTATE.EDIT : UISTATE.CREATE);
  const [record, setRecords] = useState();
  const [loading, setLoading] = useOutlet('loading');
  const [profile] = useOutlet('user');
  const [jwtToken] = useOutlet('jwtToken');
  const [actions] = useOutlet('actions');

  useEffect(() => {
    (async () => {
      if (id && profile) {
        setLoading(true);
        try {
          let record = await JStorage.fetchOneDocument(
            'Article_Default',
            {
              id,
            },
            null,
            {anonymous: true},
          );
          setRecords(record);
        } catch (err) {
          errorHandler(err);
        }
        setLoading(false);
      }
    })();
  }, [id, profile]);

  useEffect(() => {
    if (profile) {
      actions.getJwtToken();
    }
  }, [profile]);

  return loading ? (
    <h2>正在載入...</h2>
  ) : (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
      </Row>

      <Block style={{display: 'flex', flexDirection: 'column'}}>
        <h2>{uiState === UISTATE.CREATE ? '新增文章' : '編輯文章'}</h2>
        <div className="divider" />
        <div
          className="content"
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 600,
            maxHeight: 800,
          }}>
          <ArticleEditor
            document={record}
            token={jwtToken}
            onClose={() => navigate('/blogs/')}
          />
        </div>
      </Block>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;

// not use widget's block, because css would be overwrite
// const _Block = styled.div`
//   background-color: #fff;
//   flex: 1;
//   border-top: 9px solid #4d525d;
//   border-radius: 5px;
//   margin: 10px;

//   h1 {
//     font-size: 2em;
//   }
//   h2 {
//     font-size: 1.5em;
//   }

//   & > h3 {
//     font-size: 20px;
//     padding: 10px 25px;
//     border-bottom: 1px solid #eaeaea;
//     letter-spacing: 2px;
//     margin-bottom: 0;
//     color: #19233b;
//   }

//   & > .content {
//     padding: 31px 23px;
//   }
// `;
